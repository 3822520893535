<template>
  <div id="data-list-list-view" class="data-list-container">

    <vs-table
      ref="table"
      multiple
      v-model="selected"
      :max-items="itemsPerPage"
      search
      :data="orders"
    >


      <template slot="thead">
        <vs-th sort-key="id">رقم الطلب</vs-th>
        <vs-th sort-key="total">المبلغ الإجمالي</vs-th>
        <vs-th sort-key="created_at">تاريخ التوصيل</vs-th>
        <vs-th>للزبون</vs-th>
        <vs-th sort-key="order_status_id">حالة الطلب</vs-th>
        <vs-th>العمليات</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              <p class="product-name font-medium truncate">{{ tr.id }}</p>
            </vs-td>

            <vs-td>
              <p class="product-category">{{ tr.total }}LYD</p>
            </vs-td>

            <vs-td>
              <vs-chip class="product-order-status">
                {{ tr.delivery_date }}
              </vs-chip>
            </vs-td>

            <vs-td>
              {{ tr.user.username }}
            </vs-td>

            <vs-td>
              <vs-chip
                transparent :color="getOrderStatusColor(tr.order_status.name)"
                class="product-order-status"
              >
                {{ tr.order_status.name }}</vs-chip
              >

            </vs-td>

            <vs-td class="whitespace-no-wrap">
              <feather-icon
                icon="RepeatIcon"
                svgClasses="w-5 h-5 hover:text-warning stroke-current"
                @click.stop="retrieveData(tr)"
              />
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <vs-pagination
      class="mt-4"
      :total="lastPage"
      v-model="current_Page"
      @change="getData(current_Page)"
    ></vs-pagination>
  </div>
</template>

<script>
import vSelect from "vue-select";
import moduleOrder from "@/store/orders/moduleOrder.js";
export default {
  components: {
    vSelect,
  },
  data() {
    return {
      selected: [],
      orders: [
        {
          id:1,
          total:200,
          delivery_date: '2020-05-24',
          user:{
            username: 'محمود أحمد'
          },
          order_status:{
            name: 'تم التوصيل'
          }
        },
        {
          id:1,
          total:200,
          delivery_date: '2020-05-24',
          user:{
            username: 'محمود أحمد'
          },
          order_status:{
            name: 'تم الرفض'
          }
        },
        {
          id:1,
          total:200,
          delivery_date: '2020-05-24',
          user:{
            username: 'محمود أحمد'
          },
          order_status:{
            name: 'معلق'
          }
        },
        {
          id:1,
          total:200,
          delivery_date: '2020-05-24',
          user:{
            username: 'محمود أحمد'
          },
          order_status:{
            name: 'قيد التجهيز'
          }
        },
        {
          id:1,
          total:200,
          delivery_date: '2020-05-24',
          user:{
            username: 'محمود أحمد'
          },
          order_status:{
            name: 'قيد المراجعة'
          }
        },
      ],
      itemsPerPage:'',
      totalItems:'',
      lastPage:''

    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.orders.length;
    },
  },
  methods: {
    getData(page) {
      this.$store.dispatch("order/fetchOrders", page).then((response) => {
        this.orders = response.data.orders.data;
        this.itemsPerPage = response.data.orders.per_page;
        this.totalItems = response.data.orders.total;
        this.lastPage = response.data.orders.last_page;

      });
    },

    retrieveData(tr) {

    },

    getOrderStatusColor(status) {
      if (status == "قيد المراجعة") return "warning";
      if (status == "تم التوصيل") return "success";
      if (status == "تم الرفض") return "danger";
      if (status == "قيد التجهيز") return "primary";
      if (status == "معلق") return "#f7cb73";
      return "primary";
    },

  mounted() {
    this.isMounted = true;
  },
  created() {
    if (!moduleOrder.isRegistered) {
      this.$store.registerModule("order", moduleOrder);
      moduleOrder.isRegistered = true;
    }

  },
  }
};
</script>
